import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import Grid from "../../components/Grid";
import Layout from "../../components/Layout";
import Typography from "../../components/Typography";
import animationData from "../../lotties/coming-soon.json";

export const ComingSoonPageTemplate = ({ seo, title, location }) => (
  <Layout location={location}>
    <Helmet
      title={seo?.title}
      meta={[
        {
          name: "description",
          content: seo?.description,
        },
      ]}
    />
    <Grid container className="pt-40 pb-144 px-0 px-sm-15">
      <Typography variant="display-1" align="center" className="mb-40">
        {title}
      </Typography>
      <Lottie
        width="100%"
        isClickToPauseDisabled
        options={{
          loop: true,
          autoplay: true,
          animationData,
          renderSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        ariaRole="display"
      />
    </Grid>
  </Layout>
);

ComingSoonPageTemplate.propTypes = {
  seo: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
};

ComingSoonPageTemplate.defaultProps = {
  title: null,
  location: undefined,
  seo: {},
};

const ComingSoonPage = ({ data, location }) => {
  const { frontmatter } = data?.markdownRemark || {};
  const { seo, soon } = frontmatter ?? {};
  const { title } = soon;

  return <ComingSoonPageTemplate seo={seo} title={title} location={location} />;
};

ComingSoonPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

ComingSoonPage.defaultProps = {
  data: undefined,
  location: undefined,
};

export default ComingSoonPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        seo {
          title
          description
        }
        soon {
          title
        }
      }
    }
  }
`;
